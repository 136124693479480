* {
    padding: 0;
    margin: 0;
}

.body {
    color: white;
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 0px;
}

.first-div {
    width: 50%;
}

.second-div {
    width: 50%;
    margin-right: 50px;
}



.second-div img {
    width: 100%;
}

.second_body {
    display: flex;
    width: 100%;
    height: 600px;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

.first-div_second_body {
    width: 50%;
}

.first-div_second_body img {
    width: 100%;
}


.second-div_second_body {
    width: 50%;
}

.card {
    width: 100%;
    padding: 0 150px;
    display: flex;

}


.card_item {
    width: 300px;
    padding: 20px;
    background-color: blue;
    float: left;
    border-radius: 4px;
}

.ds {
    display: flex;
    justify-content: space-between;
}

.ds1 {
    height: 400px;
    padding: 0 100px;
    background-color: paleturquoise;
}

.slider-image {
    height: 600px;
}
.unlimited{
color: rgb(0, 0, 0);
}

@media screen and (max-width:500px) {
    .body {
        display: block;
        padding: 20px;
    }

    .first-div {
        width: 100%;
    }

    .second-div {
        width: 100%;
    }

    .second_body {
        display: block;
        padding: 50px;
    }

    .first-div_second_body {
        width: 100%;
    }

    .second-div_second_body {
        width: 100%;
    }

    .ds {
        display: inline-block;
        justify-content: space-between;
    }

    .ds1 {
        height: 550px;
        padding: 0 50px;
    }

    .slider-image {
        margin-top: -100px;
        margin-bottom: -100px;
        height: 500px;
        /* object-fit: cover; */
    }

}