* {
    padding: 0%;
    margin: 0;
}

.img{
    height:300px;
    border-radius: 10px;
    /* object-fit:contain;
    object-position:left center; */

}

.title {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    width: 120%;
    margin-left: -15px;
    color: rgb(60, 60, 60);
    font-size: 15px;
}

.price {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    width: 108%;
    margin-left: -15px;
    word-break: break-all;
    color: black;
    font-weight: bold;
    font-size: 20px;
}


.navlink {
    text-decoration: none;
    display: block;
    width: 100%;
    margin: 0px;
    overflow: hidden;
}

.add-to-cart{
    text-decoration: none;
    margin-left: -15px;
}

@media screen and (max-width:500px) {
    .img{
        width: 50%;
        margin: auto;
        height:400px;
        border-radius: 10px;
        /* padding:20px; */
        /* object-fit:contain;
        object-position:left center; */
    
    }
}